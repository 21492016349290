<template>
	<Offer>
		<template #banner>
			<img slot="banner" v-if="mobile === false" src="../../../../../assets/images/banners/it.png" alt="banner">
			<img slot="banner" v-else src="../../../../../assets/images/mobile/it.png" alt="banner">
		</template>

		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/offer">Wróć do spisu usług</router-link>
		</template>

		<template #small-title>
			<h4>PRAWO GOSPODARCZE</h4>
		</template>

		<template #title>
			<h2>Nowe technologie i branża IT</h2>
		</template>

		<template #text>
			<p>Branża nowych technologii jest jedną z najlepiej i najszybciej rozwijających się gałęzi gospodarki. Mając
				na względzie szybkość, z jaką zmieniają się przepisy prawa, oferujemy wsparcie prawne oraz kompleksową
				obsługę dla branży IT i nowych technologii oraz stale monitorujemy dynamiczny rozwój tego sektora
				gospodarki.</p>

			<p>

				Zapewniamy także pomoc w przygotowaniu oraz realizacji projektów z wykorzystaniem nowoczesnych
				rozwiązań, aplikacji mobilnych i Internetu. Specjalizujemy się w tworzeniu, opiniowaniu i negocjowaniu
				kontraktów oraz umów, w tym z uwzględnieniem ochrony praw autorskich majątkowych, wdrożeniowych,
				serwisowych, szkoleniowych.
			</p>

			<p>

				Doradzamy zarówno twórcom oprogramowania, aplikacji, jak i hostingodawcom, start-upom prowadzącym
				działalność w Internecie.
			</p>
			<p>


				Rozwiązania dopasowujemy do indywidualnych potrzeb Klientów, mając na względzie udzielenie im jak
				najlepszego wsparcia w zakresie prowadzenia przedsiębiorstw z obszaru IT oraz nowych technologii.</p>
		</template>

		<template #range>
			<h3>Zakres:</h3>
		</template>
		<template #list>
			<ul class="offer__list">
				<li class="offer__list-item">Bieżąca obsługa branży IT</li>
				<li class="offer__list-item">Tworzenie oraz opiniowanie regulaminów i OWU
				Umowy wdrożeniowe, maintenance i inne</li>
				<li class="offer__list-item">Przeprowadzanie audytów i testów regulacyjnych</li>
				<li class="offer__list-item">Reprezentowanie w sporach na etapach przedsądowych oraz sądowych</li>
				<li class="offer__list-item">Kompleksowa obsługa prawna software house’ów</li>

			</ul>
		</template>
	</Offer>
</template>

<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";

export default {
	mixins: [handleWith],
	components: {
		Offer
	},
	name: 'it',
}
</script>